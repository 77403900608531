<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">


        <table border="0" cellspacing="0" cellpadding="0" class="kanun">
          <tbody>
            <tr>
              <td>
                <div>
                  <table border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td  style="text-align: left">
                          <p>15 Temmuz 2015 ÇARŞAMBA</p>
                        </td>
                        <td style="text-align: center">
                          <p><strong>Resmî Gazete</strong></p>
                        </td>
                        <td  style="text-align: right">
                          <p>Sayı: 29417</p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" style="text-align: center">
                          <p><strong>YÖNETMELİK</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" >


                          <div style="text-align: center">

                              <p>Gümrük ve Ticaret Bakanlığından:</p>
                              <p>TİCARİ İLETİŞİM VE TİCARİ ELEKTRONİK İLETİLER</p>
                              <p>HAKKINDA YÖNETMELİK</p>
                              
                          </div>
                          <br>
                          <br>


                          <p><strong>BİRİNCİ BÖLÜM</strong></p>
                          <p>Amaç, Kapsam, Dayanak ve Tanımlar</p>
                          <br>
                          <br>
                          <p><strong>Amaç</strong></p>
                          <p><strong>MADDE 1 - </strong>(1) Bu Yönetmeliğin amacı, elektronik
                            iletişim araçlarıyla yapılan ticari iletişime dair bilgi verme
                            yükümlülüklerine ve ticari elektronik iletilerde uyulması gereken
                            hususlara ilişkin usul ve esasları düzenlemektir.</p>
                            <br>
                          <br>
                          <p><strong>Kapsam</strong></p>
                          <p><strong>MADDE 2 -</strong> (1) Bu Yönetmelik, gerçek ve tüzel kişilerin
                            mal ve hizmetlerini tanıtmak, pazarlamak ya da işletmesini tanıtmak veya
                            bunları başkaları adına yapmak amacıyla elektronik iletişim araçlarıyla
                            yapılan her türlü ticari iletişimi kapsar.</p>
                          <p>(2) Bu Yönetmelik hükümleri;</p>
                          <p>a) 5/11/2008 tarihli ve 5809 sayılı Elektronik Haberleşme Kanunu
                            kapsamındaki işletmecilerin abone ve kullanıcılarına; münhasıran kendi
                            mal ve hizmetlerini tanıtmak, pazarlamak ya da işletmesini tanıtmak
                            amacıyla gönderdiği ticari elektronik iletilere,</p>
                          <p>b) Vakıf üniversitelerinin öğrencilerine ve bunların velilerine
                            gönderdiği iletilere,</p>
                          <p>c) Kamu kurumu niteliğindeki meslek kuruluşları ile kamuya yararlı
                            dernekler ve vergi muafiyeti sağlanan vakıfların, kendilerine ait ticari
                            işletmelerin faaliyetleriyle ilgili olarak üyelerine gönderdiği
                            iletilere,</p>
                          <p>ç) 15/2/2011 tarihli ve 6112 sayılı Radyo ve Televizyonların Kuruluş ve
                            Yayın Hizmetleri Hakkında Kanun hükümlerine göre radyo ve televizyon
                            yayıncılığı yapan kuruluşlarca, kamuoyunu bilgilendirmek ve eğitmek
                            amacıyla yapılan yayın hizmetlerine ilişkin bilgilendirme iletilerine,
                          </p>
                          <p>d) Devlet, mahalli idareler ve diğer kamu tüzel kişilerinin kamuoyunu
                            bilgilendirmek amacıyla gönderdikleri iletilere,</p>
                          <p>uygulanmaz.</p>
                          <p><strong>Dayanak</strong></p>
                          <p><strong>MADDE 3 -</strong> (1) Bu Yönetmelik, 23/10/2014 tarihli ve
                            6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanuna
                            dayanılarak hazırlanmıştır.</p>
                          <p><strong>Tanımlar</strong></p>
                          <p><strong>MADDE 4 -</strong> (1) Bu Yönetmelikte geçen;</p>
                          <p>a) Alıcı: Tüketiciyi ya da meslekî veya diğer amaçlarla hareket eden
                            gerçek veya tüzel kişiyi,</p>
                          <p>b) Aracı hizmet sağlayıcı: Başkalarına ait iktisadî ve ticari
                            faaliyetlerin yapılmasına elektronik ticaret ortamını sağlayan gerçek ve
                            tüzel kişileri,</p>
                          <p>c) Bakanlık: Gümrük ve Ticaret Bakanlığını,</p>
                          <p>ç) Bayi işletme: Sözleşmeye dayalı olarak bir işletmenin mal ve
                            hizmetlerinin satışına aracılık eden ve kendi adına bağımsız çalışan
                            işletmeyi,</p>
                          <p>d) Elektronik iletişim adresi: Elektronik posta ve telefon numarası
                            gibi elektronik ortamda münhasıran iletişim kurmayı sağlayan adresi,</p>
                          <p>e) Elektronik iletişim araçları: İnternet ve diğer iletişim ağları
                            üzerinden iletilerin gönderilmesine, alınmasına veya saklanmasına imkân
                            sağlayan bilgisayar, telefon, faks, otomatik arama makineleri gibi her
                            türlü cihazı,</p>
                          <p>f) Elektronik ortam: Verilerin sayısallaştırılarak işlenmesi,
                            saklanması ve iletilmesinin sağlandığı ortamı,</p>
                          <p>g) Elektronik ticaret: Fizikî olarak karşı karşıya gelmeksizin,
                            elektronik ortamda gerçekleştirilen çevrim içi iktisadi ve ticari her
                            türlü faaliyeti,</p>
                          <p>ğ) Hizmet sağlayıcı: Elektronik ticaret faaliyetinde bulunan gerçek ya
                            da tüzel kişileri,</p>
                          <p>h) İl müdürlüğü: Ticaret il müdürlüğünü,</p>
                          <p>ı) İl müdürü: Ticaret il müdürünü,</p>
                          <p>i) İş günü: Ulusal bayram ile genel ve hafta sonu tatil günleri hariç
                            diğer günleri,</p>
                          <p>j) Kanun: 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında
                            Kanunu,</p>
                          <p>k) MERSİS: Merkezi Sicil Kayıt Sistemini,</p>
                          <p>l) Özel yetkili işletme: Sözleşmeye dayalı olarak bir işletmenin,
                            yönetim ve organizasyon ile dağıtım veya pazarlama teknolojileri gibi
                            konularda bilgi ve desteğini almak suretiyle bu işletmenin mal veya
                            hizmetinin satışı üzerindeki imtiyaz hakkını bedel, bölge ve süre gibi
                            belirli şartlar ve sınırlamalar dâhilinde kullanan bağımsız ticari
                            işletmeyi,</p>
                          <p>m) Ticari elektronik ileti: Telefon, çağrı merkezleri, faks, otomatik
                            arama makineleri, akıllı ses kaydedici sistemler, elektronik posta, kısa
                            mesaj hizmeti gibi vasıtalar kullanılarak elektronik ortamda
                            gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü
                            içerikli iletileri,</p>
                          <p>n) Ticari iletişim: Alan adları ve elektronik posta adresi dışında,
                            meslekî veya ticari faaliyet kapsamında kazanç sağlamaya yönelik olarak
                            elektronik ticarete ilişkin her türlü iletişimi,</p>
                          <p>o) URL adresi: İlgili içeriğin internet gibi bir ağ üzerinde bulunduğu
                            tam adresi,</p>
                          <p>ifade eder.</p>
                          <p>İKİNCİ BÖLÜM</p>
                          <p>Ticari Elektronik İletiler</p>
                          <p><strong>Ticari elektronik iletiler ve onay</strong></p>
                          <p><strong>MADDE 5 -</strong> (1) Hizmet sağlayıcının, mal ve hizmetlerini
                            tanıtmak, pazarlamak, işletmesini tanıtmak ya da kutlama ve temenni gibi
                            içeriklerle tanınırlığını artırmak amacıyla alıcıların elektronik
                            iletişim adreslerine gönderdiği ticari elektronik iletiler için kendisi
                            tarafından önceden onay alınır. Onay, reddetme hakkı kullanılıncaya
                            kadar geçerlidir.</p>
                          <p><strong>Onay gerektirmeyen durumlar</strong></p>
                          <p><strong>MADDE 6 -</strong> (1) Alıcının kendisiyle iletişime geçilmesi
                            amacıyla iletişim bilgilerini vermesi hâlinde, temin edilen mal veya
                            hizmetlere ilişkin değişiklik, kullanım ve bakıma yönelik ticari
                            elektronik iletiler için ayrıca onay alınmaz.</p>
                          <p>(2) Devam eden abonelik, üyelik veya ortaklık durumu ile tahsilat, borç
                            hatırlatma, bilgi güncelleme, satın alma ve teslimat veya benzeri
                            durumlara ilişkin bildirimleri içeren iletiler ile hizmet sağlayıcıya
                            ilgili mevzuatla getirilen bilgi verme yükümlülüğü durumlarında önceden
                            onay alma zorunluluğu aranmaz. Ancak bu tür bildirimlerde herhangi bir
                            mal veya hizmet özendirilemez veya bunların tanıtımı yapılamaz.</p>
                          <p>(3) Tacir veya esnaf olan alıcıların elektronik iletişim adreslerine
                            gönderilen ticari elektronik iletiler için önceden onay alınması zorunlu
                            değildir. Ancak tacir ve esnafların 9 uncu maddede yer alan reddetme
                            hakkını kullanması halinde onayları alınmadan ticari elektronik ileti
                            gönderilemez.</p>
                          <p>(4) Sermaye piyasasına ilişkin mevzuat uyarınca aracılık faaliyetinde
                            bulunan şirketlerce müşterilerine bilgilendirme amaçlı gönderilen ticari
                            elektronik iletiler için onay alınması zorunlu değildir.</p>
                          <p><strong>Onayın alınması</strong></p>
                          <p><strong>MADDE 7 -</strong> (1) Onay, yazılı olarak veya her türlü
                            elektronik iletişim aracıyla alınabilir. Onayda, alıcının ticari
                            elektronik ileti gönderilmesini kabul ettiğine dair olumlu irade beyanı,
                            adı ve soyadı ile elektronik iletişim adresi yer alır.</p>
                          <p>(2) Fiziki ortamda alınan onayda, onayı verenin imzası aranır.</p>
                          <p>(3) Onayın elektronik ortamda alınması durumunda, onayın alındığı
                            bilgisi, reddetme imkânı da tanınmak suretiyle, alıcının elektronik
                            iletişim adresine aynı gün içinde iletilir.</p>
                          <p>(4) Alıcının elektronik iletişim adresine ticari elektronik ileti
                            gönderilerek onay talebinde bulunulamaz.</p>
                          <p>(5) Onay; abonelik, satış ve üyelik sözleşmesi gibi bir sözleşmenin
                            içeriğine dahil edilerek alınıyorsa sözleşmenin sonunda, olumlu irade
                            beyanından veya imzadan önce, ticari elektronik ileti kenar başlığı
                            altında, reddetme imkanı da tanınarak en az on iki punto ile yazılarak
                            alınır.</p>
                          <p>(6) Acentelik, özel yetkili işletme ya da bayilik sözleşmesindeki
                            taraflardan birine verilen onay; bu sözleşmeye konu mal, hizmet veya
                            marka ile sınırlı olarak sözleşmenin diğer tarafı için de verilmiş kabul
                            edilir.</p>
                          <p>(7) Hizmet sağlayıcı aldığı onayı, kendi mal veya hizmetleri ile
                            birlikte olmak kaydıyla promosyon olarak sunulan mal ve hizmetler için
                            de kullanabilir. Ancak bu promosyon ilişkisinin bir sözleşmeye bağlı
                            olma şartı aranır.</p>
                          <p>(8) Onay metninde, olumlu irade beyanı önceden seçilmiş olarak yer
                            alamaz.</p>
                          <p>(9) Hizmet sağlayıcı, alıcıdan ticari elektronik ileti onayı vermesini,
                            sunduğu mal ve hizmetin temini için ön şart olarak ileri süremez.</p>
                          <p>(10) Onayın alındığına ilişkin ispat yükümlülüğü hizmet sağlayıcıya
                            aittir.</p>
                          <p><strong>Ticari iletişim ve ticari elektronik ileti</strong></p>
                          <p><strong>MADDE 8 -</strong> (1) Ticari elektronik ileti içeriğinin,
                            alıcıdan alınan onaya uygun olması gerekir.</p>
                          <p>(2) Ticari elektronik iletinin başlığında veya içeriğinde; tacirler
                            için MERSİS numarası ve ticaret unvanına, esnaflar için adı ve soyadı
                            ile T.C. kimlik numarasına yer verilir. Hizmet sağlayıcı, bunlara ek
                            olarak marka veya işletme adı gibi kendisini tanıtan diğer bilgilere yer
                            verebilir.</p>
                          <p>(3) Kısa mesaj gibi sınırlı alanlar kullanılarak gönderilen ticari
                            elektronik iletinin içeriğinde; tacirler için MERSİS numarasına,
                            esnaflar için ise adı ve soyadı ile T.C. kimlik numarasına yer verilir.
                            Hizmet sağlayıcı, bunlara ek olarak marka veya işletme adı gibi
                            kendisini tanıtan diğer bilgilere yer verebilir.</p>
                          <p>(4) Ticari elektronik iletide, elektronik iletişim aracının türüne
                            bağlı olarak hizmet sağlayıcının telefon, faks, kısa mesaj numarası ve
                            elektronik posta adresi gibi erişilebilir durumdaki iletişim
                            bilgilerinden en az birine yer verilir.</p>
                          <p>(5) Ticari elektronik iletinin niteliği içeriğinden açık bir biçimde
                            anlaşılamıyorsa tanıtım, kampanya ve bilgilendirme gibi niteliği
                            belirleyici bir ibareye yer verilir. Bu ibare; kısa mesaj yoluyla
                            gönderilen iletilerde iletinin başlangıcında, elektronik posta yoluyla
                            gönderilen iletilerde konu bölümünde, sesli aramalarda ise görüşmenin
                            başlangıcında belirtilir.</p>
                          <p>(6) Ticari elektronik iletide indirim ve hediye gibi promosyonlar ile
                            promosyon amaçlı yarışma veya oyunlar olması halinde bu husus iletide
                            açıkça belirtilir.</p>
                          <p>(7) Promosyonların geçerlilik süresi ve alıcının bunlardan faydalanmak
                            için yerine getirmek zorunda olduğu yükümlülüklere ilişkin şartlar, açık
                            ve şüpheye yer vermeyecek şekilde, bu hususlara özgülenmiş bir URL
                            adresi veya müşteri hizmetleri numarası gibi kolay bir şekilde
                            ulaşılabilecek yöntemlerle sunulur.</p>
                          <p><strong>Reddetme hakkı ve bildirim yöntemi</strong></p>
                          <p><strong>MADDE 9 -</strong> (1) Alıcı istediğinde hiçbir gerekçe
                            göstermeksizin ticari elektronik ileti almayı reddedebilir. Alıcının ret
                            bildiriminde bulunması, bildirimin yapıldığı iletişim kanalına ilişkin
                            onayı geçersiz kılar.</p>
                          <p>(2) 7 nci maddenin altıncı fıkrası kapsamında verilen onay için
                            taraflardan birine yapılacak ret bildirimi tarafların tümüne yapılmış
                            sayılır. Ret bildirimini alan taraf, bu hususu diğer tarafa bildirmekle
                            yükümlüdür.</p>
                          <p>(3) Hizmet sağlayıcının, alıcının ret bildirimi için ticari elektronik
                            iletide, müşteri hizmetleri numarası, kısa mesaj numarası veya yalnızca
                            ret bildirimine özgülenmiş bir URL adresi gibi erişilebilir iletişim
                            adresini vermesi gerekir. Ticari elektronik ileti hangi iletişim
                            kanalıyla gönderildiyse ret bildirimi de kolay ve ücretsiz bir şekilde
                            olmak üzere aynı iletişim kanalıyla sağlanır.</p>
                          <p>(4) Ret bildirimi imkânı, gönderilen her ticari elektronik iletide yer
                            alır.</p>
                          <p>(5) Alıcı tarafından reddetme hakkının kullanılmış olması, hizmet
                            sağlayıcının tabi olduğu ilgili mevzuat hükümlerine göre alıcıya
                            gönderilmesi zorunlu olan bildirimlerin yapılmasına engel teşkil etmez.
                          </p>
                          <p><strong>Ret bildiriminin uygulanması</strong></p>
                          <p><strong>MADDE 10 -</strong> (1) Hizmet sağlayıcı, alıcının ticari
                            elektronik iletiyi almayı reddettiğine ilişkin talebinin kendisine
                            ulaşmasını müteakip üç iş günü içinde alıcıya ticari elektronik ileti
                            göndermeyi durdurur.</p>
                          <p>ÜÇÜNCÜ BÖLÜM</p>
                          <p>Aracı Hizmet Sağlayıcılar, Kişisel Verilerin Korunması,</p>
                          <p>İspat Yükümlülüğü ve Kayıtları Saklama Süresi</p>
                          <p><strong>Aracı hizmet sağlayıcılara ilişkin yükümlülükler ve diğer
                              hususlar</strong></p>
                          <p><strong>MADDE 11 </strong>- (1) Hizmet sağlayıcı, önceden onayını
                            aldığı alıcılara ticari elektronik iletileri kendisi gönderebileceği
                            gibi aracı hizmet sağlayıcılar vasıtasıyla da gönderebilir.</p>
                          <p>(2) Aracı hizmet sağlayıcı;</p>
                          <p>a) Hizmet sağlayıcı tarafından hazırlanmış içerikleri gönderir.</p>
                          <p>b) Ticari elektronik iletinin oluşturulabilmesi, gönderilebilmesi,
                            alınabilmesi, depolanabilmesi ve alıcıların bilgilerinin saklanabilmesi
                            ve işlenebilmesi için gerekli olan yazılım, donanım ile veri tabanı ve
                            yönetim sistemini sağlar.</p>
                          <p>c) Bu Yönetmelikle hizmet sağlayıcı için öngörülen diğer
                            yükümlülüklerin ifasına yönelik imkânı tanır.</p>
                          <p>(3) Aracı hizmet sağlayıcı, gönderilen ticari elektronik iletilerin
                            içeriğinde kendisine ait marka adı, ticaret unvanı veya işletme adı
                            bilgilerinin en az birine yer verir.</p>
                          <p>(4) Aracı hizmet sağlayıcı, hizmet sunduğu elektronik ortamı kullanan
                            gerçek ve tüzel kişiler tarafından sağlanan içerikleri kontrol etmek, bu
                            içerik ve içeriğe konu mal veya hizmetle ilgili hukuka aykırı bir
                            faaliyetin ya da durumun söz konusu olup olmadığını araştırmakla yükümlü
                            değildir.</p>
                          <p>(5) Aracı hizmet sağlayıcı başkaları adına, onların mal ve hizmetlerini
                            tanıtmak, pazarlamak ya da işletmesini tanıtmak amacıyla ticari
                            elektronik ileti göndermek için onay alamaz.</p>
                          <p><strong>Kişisel verilerin korunması</strong></p>
                          <p><strong>MADDE 12 -</strong> (1) Hizmet sağlayıcı ve aracı hizmet
                            sağlayıcı, bu Yönetmelik çerçevesinde yapmış olduğu işlemler ve sunduğu
                            hizmetler nedeniyle elde ettiği verilerin, ilgili mevzuat hükümleri
                            saklı kalmak kaydıyla muhafazasından ve hukuka aykırı olarak bunlara
                            erişilmesini ve işlenmesini önlemek amacıyla gerekli tedbirlerin
                            alınmasından sorumludur.</p>
                          <p>(2) Kişisel verilerin; üçüncü kişilerle paylaşılabilmesi, işlenebilmesi
                            ve başka amaçlarla kullanılabilmesi için ilgili kişiden önceden onay
                            alınması gerekir.</p>
                          <p><strong>İspat yükümlülüğü ve kayıtları saklama süresi</strong></p>
                          <p><strong>MADDE 13 - </strong>(1) Şikâyet konusu işlemlerde ispat
                            yükümlülüğü hizmet sağlayıcıya ve/veya aracı hizmet sağlayıcıya aittir.
                          </p>
                          <p>(2) Hizmet sağlayıcı ve/veya aracı hizmet sağlayıcı onay kayıtlarını,
                            onayın geçerliliğinin sona erdiği tarihten, ticari elektronik iletilere
                            ilişkin diğer kayıtları ise kayıt tarihinden itibaren bir yıl süreyle
                            saklar. Talep edilmesi halinde bu kayıtlar Bakanlığa sunulur.</p>
                          <p>DÖRDÜNCÜ BÖLÜM</p>
                          <p>Şikâyet, Denetim ve Yetki</p>
                          <p><strong>Şikâyet</strong></p>
                          <p><strong>MADDE 14 -</strong> (1) Şikâyet başvuruları, elektronik ortamda
                            e-Devlet kapısı veya Bakanlığın internet sitesi üzerinden veyahut yazılı
                            olarak şikâyetçinin ikametgâhının bulunduğu yerdeki il müdürlüğüne
                            yapılır. Şikâyetçi; gerçek kişi ise adı ve soyadı ile imzası ve
                            ikametgâh adresinin, tüzel kişi ise unvanı ve adresi ile temsile yetkili
                            kişinin veya vekilin adı ve soyadı ile imzasının başvuruda yer alması
                            gerekir.</p>
                          <p>(2) Ticari elektronik iletilere ilişkin şikâyet başvurularında
                            aşağıdaki hususlar aranır:</p>
                          <p>a) Kısa mesaj yoluyla gönderilmiş ise; şikâyetçinin T.C. kimlik
                            numarası, telefon numarası, abonesi olduğu GSM operatörünün adı, iletiyi
                            gönderenin numarası, bu numaranın bulunmaması halinde marka ve işletme
                            adı gibi alfa numerik bilgisi, iletinin gönderilme tarihi, saati ve
                            içeriğinin tamamı ile şikâyetçinin tacir olması halinde MERSİS numarası,
                            esnaf olması halinde bu durumu belirten ibare yer alır. Varsa iletinin
                            görsel bir örneği başvuruya eklenir.</p>
                          <p>b) Elektronik posta yoluyla gönderilmiş ise; şikâyetçinin T.C. kimlik
                            numarası, elektronik posta adresi, şikâyetçiye elektronik posta hizmeti
                            sağlayan işletmenin adı, iletiyi gönderenin elektronik posta adresi,
                            iletinin gönderilme tarihi, saati ve içeriği ile şikâyetçinin tacir
                            olması halinde MERSİS numarası, esnaf olması halinde bu durumu belirten
                            ibare yer alır. İletinin bir örneği başvuruya eklenir.</p>
                          <p>c) Sesli arama yoluyla yapılmışsa; şikâyetçinin T.C. kimlik numarası,
                            telefon numarası, abonesi olduğu GSM veya sabit hat operatörü adı,
                            iletiyi gönderenin numarası, bu numaranın alınamaması halinde marka ve
                            işletme adı, iletinin gönderilme tarihi, saati ve içeriği ile
                            şikâyetçinin tacir olması halinde MERSİS numarası, esnaf olması halinde
                            bu durumu belirten ibare yer alır.</p>
                          <p>ç) Diğer elektronik iletişim araçları ile yapılmışsa iletişim aracının
                            türüne bağlı olarak bu fıkrada belirtilen bilgilerden uygun olanlara yer
                            verilir.</p>
                          <p>(3) Şikâyet başvurusu, ticari elektronik iletinin gönderildiği tarihten
                            itibaren üç ay içinde yapılır.</p>
                          <p>(4) Şikâyetçi ancak kendisine ait elektronik iletişim adreslerine
                            gönderilen ticari elektronik iletilere ilişkin şikâyette bulunabilir.
                          </p>
                          <p>(5) Bu maddede yer alan şartları taşımayan başvurular il müdürlüğünce
                            işleme konulmaz.</p>
                          <p><strong>Şikâyetin sonuçlandırılması</strong></p>
                          <p><strong>MADDE 15 -</strong> (1) Başvurunun yapıldığı il müdürlüğünce,
                            şikâyet edilenin sicile kayıtlı merkezinin başka bir ilde bulunduğunun
                            tespiti halinde, başvuru belgeleri ilgili il müdürlüğüne gönderilir ve
                            başvuru sahibine bilgi verilir.</p>
                          <p>(2) İl müdürlüğü tarafından konuya ilişkin bilgi ve belgeler
                            ilgilisinden temin edilerek şikâyet sonuçlandırılır. Ancak gerekli
                            hallerde il müdürlüğünce denetim için görevlendirilen personel
                            tarafından yerinde denetim yapılır.</p>
                          <p>(3) Hizmet sağlayıcı ve aracı hizmet sağlayıcı, il müdürlüğü tarafından
                            söz konusu şikâyetle ilgili olarak talep edilen bilgi ve belgeleri, bu
                            talebin tebliğinden itibaren on beş gün içinde teslim etmekle
                            yükümlüdür. Gerekli hallerde ilgilinin talebine istinaden il
                            müdürlüğünce bu süre bir defaya mahsus olmak üzere en fazla on beş gün
                            uzatılabilir. Bu sürenin sonunda da talep edilen bilgi ve belgelerin
                            teslim edilmemesi halinde, şikâyet başvurusu sırasında il müdürlüğüne
                            sunulan bilgi ve belgeler üzerinden idari işlem tesis edilir. İl
                            müdürlüğünün bilgi ve belge talebine ilişkin yazısında, talep edilen
                            bilgi ve belgelerin süresi içinde teslim edilmemesi halinde, şikâyet
                            başvurusu sırasında il müdürlüğüne sunulan bilgi ve belgeler üzerinden
                            idari işlem tesis edileceği belirtilir.</p>
                          <p>(4) İl müdürlüğü, hizmet sağlayıcı ya da aracı hizmet sağlayıcının
                            doğrudan tespit edilmesine imkân bulunmadığı hallerde, ilgili kamu kurum
                            ve kuruluşları ile elektronik haberleşme hizmeti sunan işletmecilerden
                            bilgi ve belge talep edebilir.</p>
                          <p><strong>Denetim</strong></p>
                          <p><strong>MADDE 16 -</strong> (1) Bakanlık, hizmet sağlayıcı ve aracı
                            hizmet sağlayıcının bu Yönetmelik kapsamında gerçekleştirdiği faaliyet
                            ve işlemleri denetlemeye yetkilidir.</p>
                          <p>(2) Bakanlıkça görevlendirilen denetim elemanları, bu Yönetmelik
                            kapsamında Bakanlık yetkisine giren hususlarla ilgili olarak her türlü
                            bilgi, belge ve defterleri istemeye, bunları incelemeye ve örneklerini
                            almaya, ilgililerden yazılı ve sözlü bilgi almaya yetkilidir. İlgililer
                            istenilen bilgi, belge ve defterler ile elektronik kayıtlarını, bunların
                            örneklerini noksansız ve gerçeğe uygun olarak vermek, yazılı ve sözlü
                            bilgi taleplerini karşılamak ve gerekli yardım ve kolaylığı göstermekle
                            yükümlüdür.</p>
                          <p>(3) Bakanlıkça görevlendirilen denetim elemanları, ticari elektronik
                            iletileri gönderen hizmet sağlayıcı ve aracı hizmet sağlayıcının
                            abonelik bilgileri ile gerekli bilgi ve belgeleri ilgili diğer kamu
                            kurum ve kuruluşları ile elektronik haberleşme hizmeti sunan
                            işletmecilerden istemeye yetkilidir.</p>
                          <p><strong>İdari yaptırımları uygulama</strong></p>
                          <p><strong>MADDE 17-</strong> (1) Bu Yönetmeliğe aykırı hareket edenlere
                            Kanunun 12 nci maddesi uyarınca uygulanacak idari para cezalarını
                            vermeye, hizmet sağlayıcı ve aracı hizmet sağlayıcının sicile kayıtlı
                            merkezinin bulunduğu yerdeki il müdürü yetkilidir.</p>
                          <p>(2) Verilen idari para cezaları, tebliğ tarihinden itibaren bir ay
                            içinde ödenir.</p>
                          <p>BEŞİNCİ BÖLÜM</p>
                          <p>Çeşitli ve Son Hükümler</p>
                          <p><strong>Mevcut veri tabanlarının kullanımı</strong></p>
                          <p><strong>GEÇİCİ MADDE 1 -</strong> (1) Bu Yönetmeliğin yürürlük
                            tarihinden önce, ticari elektronik ileti gönderilmesi amacıyla alıcının
                            açık irade beyanını içerecek şekilde alınan onaylar geçerlidir.</p>
                          <p>(2) Kanunun yürürlük tarihinden önce, hizmet sağlayıcı ve alıcı
                            arasında doğrudan mal veya hizmet teminine yönelik işlemler sırasında
                            alıcının elektronik iletişim adresini vermesi ile oluşturulan veri
                            tabanlarının onaylı olduğu kabul edilir. Bu şekilde verildiği kabul
                            edilen onay; acente, özel yetkili ya da bayi işletme için verilmiş ise
                            sözleşmenin diğer tarafı için de verilmiş kabul edilir.</p>
                          <p>(3) İkinci fıkra kapsamında onay verdiği kabul edilen alıcıya, bu
                            Yönetmeliğin yayımı tarihinden sonra, reddetme hakkı da tanınarak
                            gönderilen ilk ticari elektronik iletide, gönderene ait veri tabanında
                            kayıtlı olduğuna dair bilgiye yer verilir.</p>
                          <p>(4) Kanunun yürürlük tarihinden önce, başkaları adına ticari elektronik
                            ileti gönderilmesi amacıyla genel nitelikli onay alınmış ve bu onaya
                            dayanılarak alıcıya ticari elektronik ileti gönderilmiş olması kaydıyla
                            bu Yönetmeliğin yayımı tarihinden itibaren üç ay içinde ve bir defaya
                            mahsus olmak üzere, adına ticari elektronik ileti gönderilenler
                            tarafından onay alınması amacıyla alıcılara ticari elektronik ileti
                            gönderilebilir. Bu iletide, genel onayın kim tarafından alındığı
                            bilgisine de yer verilir. Onay talebine sessiz kalınması durumunda talep
                            reddedilmiş sayılır.</p>
                          <p>(5) Bu maddede düzenlenen hususlara ilişkin ispat yükümlülüğü ticari
                            elektronik iletiyi gönderene aittir.</p>
                          <p><strong>Yürürlük</strong></p>
                          <p><strong>MADDE 18 -</strong> (1) Bu Yönetmelik yayımı tarihinde
                            yürürlüğe girer.</p>
                          <p><strong>Yürütme</strong></p>
                          <p><strong>MADDE 19 -</strong> (1) Bu Yönetmelik hükümlerini Gümrük ve
                            Ticaret Bakanı yürütür.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>



        <!-- <div class="pagelink">
          <a target="_blank"
            href="https://www.resmigazete.gov.tr/eskiler/2020/01/20200104-2.htm">04 Ocak 2020 Tarihli Yönetmeliği Görüntüle</a>
        </div> -->
        <div class="pagelink">
          <a target="_blank"
            href="https://www.resmigazete.gov.tr/eskiler/2015/07/20150715-4.htm">Resmî Gazete Üzerinde Görüntüle</a>
        </div>
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <h3>Mevzuat</h3>
        <div class="half">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>
        <div class="half">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

export default {
  name: "IysNedir",

  methods: {
    ...mapMutations("app", ["SET_MODAL"])
  }
};
</script>

<style scoped>
.staticbox:nth-child(odd) {
  flex-direction: row-reverse;
  text-align: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
